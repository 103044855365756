import React from 'react';
import connectivityImage from '../assets/connectivity-image.webp';
import smartContractImage from '../assets/smart-contract-image.webp';

function HeroSection() {
    return (
        <section style={{
            display: 'flex',
            flexDirection: 'column', // Change direction on smaller screens
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            background: '#f0f2f5',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            margin: '20px',
            position: 'relative',
        }}>
            <div style={{
                position: 'absolute',
                top: '10%',
                left: '5%',
                color: 'white',
                zIndex: 1,
                textAlign: 'center',
                maxWidth: '90%', // Ensures text doesn't overflow on small screens
            }}>
                <h1 style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '5px' }}>Empower Your Connectivity</h1>
            </div>
            <img src={connectivityImage} alt="Better Connectivity" style={{
                width: '90%', // Adjust image width for smaller screens
                margin: '10px',
                borderRadius: '8px',
            }} />
            <div style={{
                position: 'absolute',
                top: '10%',
                right: '5%',
                color: 'white',
                zIndex: 1,
                textAlign: 'center',
                maxWidth: '90%',
            }}>
                <h1 style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '10px', borderRadius: '5px' }}>Deploy with Confidence</h1>
            </div>
            <img src={smartContractImage} alt="Smart Contract Deployment" style={{
                width: '90%',
                margin: '10px',
                borderRadius: '8px',
            }} />
        </section>
    );
}

export default HeroSection;
