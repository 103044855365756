import React from 'react';
import { Typography, Paper, Grid, List, ListItem, ListItemText, Link } from '@mui/material';

function FeaturesSection() {
    return (
        <section style={{ padding: '40px 20px', backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Paper elevation={6} style={{ padding: '20px', margin: '10px' }}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Why Choose Us?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Experience seamless network connectivity optimized for blockchain applications.
                            Our platform supports major blockchains like Ethereum, Polygon, Solana, and Cardano, 
                            ensuring you can deploy and manage smart contracts with ease. Here’s how we stand out:
                        </Typography>
                        <Typography variant="body2" paragraph>
                            <strong>High Scalability:</strong> Scale your applications effortlessly to meet user demand.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            <strong>Robust Security:</strong> Advanced security protocols to safeguard your transactions.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            <strong>24/7 Support:</strong> Our experts are available round the clock to assist you with any issues.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={6} style={{ padding: '20px', margin: '10px' }}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Supported Blockchains
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="Ethereum" secondary="Leader in smart contracts and dApps" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Polygon" secondary="Enhanced scalability and interoperability" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Solana" secondary="High throughput and fast confirmation" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Cardano" secondary="Peer-reviewed research and development" />
                            </ListItem>
                        </List>
                        <Typography variant="body2" style={{ marginTop: '10px' }}>
                            Discover more about our blockchain support and capabilities on our <Link href="/blockchain-info">Blockchain Info</Link> page.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </section>
    );
}

export default FeaturesSection;
