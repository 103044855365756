import React from 'react';
import { Typography, Link, Box, Container } from '@mui/material';

function Footer() {
    const currentYear = new Date().getFullYear(); // Gets the current year

    return (
        <footer style={{ backgroundColor: '#333', padding: '20px 0', marginTop: '40px' }}>
            <Container maxWidth="lg">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant="body2" color="textSecondary" align="center" style={{ color: '#fff' }}>
                        © {currentYear} Chainsis Platform
                    </Typography>
                    <Link href="/privacy-policy" style={{ color: '#fff', marginTop: '8px', textDecoration: 'none' }}>
                        Privacy Policy
                    </Link>
                </Box>
            </Container>
        </footer>
    );
}

export default Footer;
