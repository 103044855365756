import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionPage({ selectedPlan }) {
    const [paymentMethod, setPaymentMethod] = useState('stripe');
    const history = useNavigate(); // useHistory hook for navigation

    // Redirect if no plan is selected
    useEffect(() => {
        if (!selectedPlan) {
            history.push('/plans');
        }
    }, [selectedPlan, history]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Example payload
        const subscriptionDetails = {
            planId: selectedPlan.id,
            paymentMethod,
        };

        try {
            // Placeholder for API endpoint call
            const response = await fetch('/api/subscribe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subscriptionDetails)
            });
            const data = await response.json();
            if (data.success) {
                alert('Subscription successful!');
                history.push('/dashboard'); // Redirect to the dashboard or another page
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error('Subscription failed:', error);
            alert(`Subscription failed: ${error.message}`);
        }
    };

    // Render null or a loading spinner while checking if selectedPlan exists
    if (!selectedPlan) {
        return <div>Loading...</div>; // Loading spinner or similar feedback
    }

    return (
        <div>
            <h1>Subscribe to: {selectedPlan.name}</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Pay with Stripe
                        <input type="radio" name="paymentMethod" value="stripe" checked={paymentMethod === 'stripe'} onChange={() => setPaymentMethod('stripe')} />
                    </label>
                    <label>
                        Pay with Chainsis Token
                        <input type="radio" name="paymentMethod" value="token" checked={paymentMethod === 'token'} onChange={() => setPaymentMethod('token')} />
                    </label>
                </div>
                <button type="submit">Confirm Subscription</button>
            </form>
        </div>
    );
}

export default SubscriptionPage;
