import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const [userInfo, setUserInfo] = useState({
        name: '',
        email: '',
        subscriptionType: '',
        apiUsage: { callsMade: 0, limit: 0 }
    });
    const history = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('http://localhost:4000/api/user', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setUserInfo({
                    name: response.data.name,
                    email: response.data.email,
                    subscriptionType: response.data.subscriptionType,
                    apiUsage: response.data.apiUsage
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
                if (error.response.status === 401) { // Handle 401 errors specifically
                    alert('Session expired. Please login again.');
                    history.push('/login');
                }
            }
        };

        fetchData();
    }, [history]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        history.push('/login');
    };

    return (
        <div>
            <h1>Dashboard</h1>
            <p>Welcome, {userInfo.name}</p>
            <div>
                <strong>Email:</strong> {userInfo.email}
            </div>
            <div>
                <strong>Subscription Type:</strong> {userInfo.subscriptionType}
            </div>
            <div>
                <strong>API Usage:</strong> {userInfo.apiUsage.callsMade} / {userInfo.apiUsage.limit}
            </div>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default Dashboard;
