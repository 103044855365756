import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/chainsis-logo.webp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Header() {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleNavigation = (path) => {
        navigate(path);
        setMobileOpen(false); // Close mobile menu upon clicking any link
    };

    const drawer = (
        <List>
            <ListItem button onClick={() => handleNavigation('/')}>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/products')}>
                <ListItemText primary="Products" />
            </ListItem>
            <ListItem button onClick={() => handleNavigation('/solutions')}>
                <ListItemText primary="Solutions" />
            </ListItem>
            {/* Include all other navigation links here */}
            {isLoggedIn && (
                <ListItem button onClick={() => handleNavigation('/dashboard')}>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            )}
            <ListItem button onClick={() => handleNavigation(isLoggedIn ? '/logout' : '/login')}>
                <ListItemText primary={isLoggedIn ? "Logout" : "Login"} />
            </ListItem>
            {!isLoggedIn && (
                <ListItem button onClick={() => handleNavigation('/register')}>
                    <ListItemText primary="Register" />
                </ListItem>
            )}
        </List>
    );

    return (
        <AppBar position="static">
            <Toolbar style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                {isMobile ? (
                    <>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMobileOpen(!mobileOpen)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={mobileOpen} onClose={() => setMobileOpen(false)}>
                            {drawer}
                        </Drawer>
                        <img src={logo} alt="Chainsis logo" style={{ height: '50px' }} onClick={() => handleNavigation('/')} />
                    </>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} alt="Chainsis logo" style={{ height: '50px', marginRight: '20px', cursor: 'pointer' }} onClick={() => handleNavigation('/')}/>
                        <Button color="inherit" onClick={() => handleNavigation('/products')}>Products</Button>
                        <Button color="inherit" onClick={() => handleNavigation('/solutions')}>Solutions</Button>
                        <Button color="inherit" onClick={() => handleNavigation('/developers')}>Developers</Button>
                        <Button color="inherit" onClick={() => handleNavigation('/resources')}>Resources</Button>
                        <Button color="inherit" onClick={() => handleNavigation('/pricing')}>Pricing</Button>
                        {isLoggedIn && <Button color="inherit" onClick={() => handleNavigation('/dashboard')}>Dashboard</Button>}
                    </div>
                )}
                {!isMobile && (
                    <div>
                        {isLoggedIn ? (
                            <Button color="inherit" onClick={() => handleNavigation('/logout')}>Logout</Button>
                        ) : (
                            <>
                                <Button color="inherit" onClick={() => handleNavigation('/login')}>Login</Button>
                                <Button color="inherit" onClick={() => handleNavigation('/register')}>Register</Button>
                            </>
                        )}
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
