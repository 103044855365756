import React, { useState, useEffect } from 'react';

function SubscriptionPlanSelection({ onSelectPlan }) {
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        // Fetch subscription plans from the backend
        async function fetchPlans() {
            const response = await fetch('/api/plans');
            const data = await response.json();
            setPlans(data);
        }
        fetchPlans();
    }, []);

    return (
        <div>
            <h2>Select a Subscription Plan</h2>
            <ul>
                {plans.map(plan => (
                    <li key={plan.id}>
                        <button onClick={() => onSelectPlan(plan)}>
                            {plan.name} - {plan.price}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SubscriptionPlanSelection;
